import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalBody } from "reactstrap";
import { toastr } from "react-redux-toastr";
import Axios from "axios";
import Pdfviewer from "./Pdfviewer";
import DocImage from "./../doc_image.png"
import TiffImage from "./../tiff_image.png"
import '../../../../components/Settings/Profile/Profile.css'

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const FileViewModal = (props) => {
  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const [Preview, setPreview] = useState("");

  const toggle = (index) => {
    // Toggle selected element
    
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const getBase64 = () => {
    Axios.get(`${props.path}`, {
      responseType: "arraybuffer",
    }).then((response) =>
      setPreview(Buffer.from(response.data, "binary").toString("base64"))
    );
  };

  const DownloadFile = (index) => {
    Axios.get(`${props.path}/download`, { responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Document Downloaded",
            "Document downloaded successfully"
          );
          toggle(index);
        }
      })
      .catch((error) => {});
  };

  const DeleteFile = (index) => {
    Axios.get(`/patient/document/delete/${props.id}`)
      .then((res) => {
        if (res.status === 200) {
          props.RenderDocuments();
          toastr.success("Document Deleted", "Document deleted successfully");
          toggle(index);
        }
      })
      .catch((error) => {});
  };


  const OverlayLoader = () => (
    <div class="overlay">
      <div class="overlay__inner">
        <div class="overlay__content">
          <span class="spinner"></span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    getBase64();
  }, []); // eslint-disable-line

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <span onClick={() => toggle(index)}>{props.title}</span>
          
          <Modal
            isOpen={openModals[index]}
            toggle={() => toggle(index)}
            centered
            >
            <ModalBody>
              <div className="document-title">
                <p>
                  <strong>Title : </strong>
                  {props.title}
                </p>
              </div>
            
              {!Preview ? <div className="doc-viewer-spinner"><OverlayLoader /></div> : ""}

              {Preview && props.filetype === "image"? (
                <div className="file-preview">
                  <img
                    src={`data:image/jpeg;base64,${Preview}`}
                    className="img-fluid"
                    alt="file-preview"
                  />
                </div>
              ) : null}

              {Preview && props.filetype === "pdf" ? (
                <div className="file-preview">
                  <Pdfviewer url={Preview} />
                </div>
              ) : null} 

              {Preview && props.filetype === "doc" ? (
                <div className="file-preview">
                  <img src={DocImage} className="doc-type-image" alt="file-preview"></img>
                </div>
              ) : null}

              {Preview && props.filetype === "tiff" ? (
                <div className="file-preview">
                  <img src={TiffImage} className="doc-type-image" alt="file-preview"></img>
                </div>
              ) : null}


              <div className="preview-buttons">
                <Button
                  color="primary"
                  className="btn-md ml-2"
                  onClick={() => DownloadFile(index)}
                >
                  Download
                </Button>

                <Button
                  className="outline-primary btn btn-secondary btn-md ml-2"
                  onClick={() => DeleteFile(index)}
                >
                  Delete
                </Button>
              </div>
              <div className="modal-close">
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => toggle(index)}
                />
              </div>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default FileViewModal;

import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import "./Layout.css";
import defaultAvatar from "../../assets/avatar.png";
import logo from "../../assets/equipo_logo_060117_white.png";
import logoicon from "../../assets/NVC_Equipo_LOGO.png";
import { GlobalState } from "./../../context/ContextApi";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import {
  FcHome,
  FcCalendar,
  FcCallback,
  FcInspection,
  FcDocument,
  FcConferenceCall,
  FcSettings,
} from "react-icons/fc";

import homeicon from "../../assets/newLogos/home.png";
import myappointment from "../../assets/newLogos/my-appointment.png";
import bookappointment from "../../assets/newLogos/book-appointment.png";
import healthprofile from "../../assets/newLogos/health-profile.png";
import clinicalrecord from "../../assets/newLogos/my-clinical-record.png";
import careteam from "../../assets/newLogos/care-team.png";
import myaccount from "../../assets/newLogos/my-account.png";
import mydependents from "../../assets/newLogos/my-dependents.png"
import { cookieService } from '../../services/cookieService';

let dependentID = cookieService.get('dependentID');
const category = [
  {
    name: "Home",
    path: "/home",
    icon: FcHome,
    isIconAvailable: true,
    isVisible: true,
    iconAsset: homeicon
  },
  {
    name: "My Appointments",
    path: "/my-appointments",
    icon: FcCalendar,
    isIconAvailable: true,
    iconAsset: myappointment
  },
  {
    name: "Book Appointment",
    path: "/book-appointment",
    icon: FcCallback,
    isIconAvailable: true,
    iconAsset: bookappointment
  },
  {
    name: "Health Profile",
    path: "/health-profile",
    icon: FcInspection,
    isIconAvailable: true,
    iconAsset: healthprofile
  },
  {
    name: "My Clinical Records",
    path: "/my-clinical-records",
    icon: FcDocument,
    isIconAvailable: true,
    iconAsset: clinicalrecord
  },
  {
    name: "Care Team",
    path: "/care-team",
    icon: FcConferenceCall,
    isIconAvailable: true,
    iconAsset: careteam
  },
  {
    name: "My Dependents",
    path: "/my-dependents",
    icon: FcConferenceCall,
    isIconAvailable: dependentID == 0 ? true : false,
    iconAsset: mydependents
  },
  {
    name: "My Account",
    path: "/settings/profile",
    icon: FcSettings,
    isIconAvailable: true,
    iconAsset: myaccount
  },
];



const SidebarItem = ({
  name,
  icon: Icon,
  to,
  styleName,
  isIconAvailable,
  iconAsset,
}) => {
  const { alterSidebar } = useContext(GlobalState);

  return (
    <>
      <ReactTooltip className="card-tooltip" />
      <li className="sidebar-item" data-tip={name}>
        <a
          href={to}
          target={alterSidebar ? "_blank" : ""}
          className="sidebar-link"
          activeClassName="active"
        >
          {Icon ? (
            <IconContext.Provider
              value={{ style: { fontSize: "30px" }, className: "align-middle mr-2" }}
            >
              {isIconAvailable && iconAsset ? (
                <div className="new-icons">
                  <div className="">
                    <img
                      src={iconAsset}
                      alt="icon-section"
                      className="icon-logo img-fluid align-middle mb-2"
                    />{" "}
                    <span className="sidebar_title sidebar-titleSection">
                      {name}
                    </span>
                  </div>
                </div>
              ) : (
                <>
                  <Icon /> <span className="align-middle">{name}</span>{" "}
                </>
              )}
            </IconContext.Provider>
          ) : (
            name
          )}{" "}
        </a>
      </li>
    </>
  );
};

const Sidebar = (props) => {
  const { Details, FirstName, LastName, hideMenu, setHideMenu } = useContext(
    GlobalState
  );
  const avatar = Details.profileImageURL ? Details.profileImageURL : defaultAvatar;

  // SIDEBAR AUTO HIDE LOGIC
  let location = useLocation();

  const handleSidebarToggle = () => {
    if (window.screen.width <= 768) {
      setHideMenu(true);
    }
  };

  useEffect(() => {
    handleSidebarToggle();
  }, [location]); //eslint-disable-line

  useEffect(() => {
    setHideMenu(true);
  }, []);//eslint-disable-line

  return (
    <>
      <nav
        className={classNames("sidebar", "disable-scrollbars", hideMenu && "toggled")}
      >
        <div className="sidebar-content">
          <a className={classNames("sidebar-brand ")} href="/">
            {hideMenu && window.innerWidth > 1024 ? (
              <div style={{ marginLeft: 5 }}>
                <img
                  style={{ height: 40 }}
                  src={logoicon}
                  alt="Equipo APP logo"
                  className="img-fluid"
                />
              </div>
            ) : (
              <img
                src={logo}
                alt="Equipo APP logo"
                className="img-fluid"
              />
            )}
          </a>

          {!hideMenu ? (
            <div className="sidebar-user">
              <img
                src={avatar}
                className="img-fluid rounded-circle mb-2"
                alt="Profile pic"
              />
              <div className="font-weight-bold">
                {FirstName + " " + LastName}
              </div>
            </div>
          ) : null}

          <ul className="sidebar-nav">
            {category.map((category, index) => {
              if(category.isIconAvailable)
              return (
                <SidebarItem
                  key={index.toString()}
                  name={category.name}
                  to={category.path}
                  icon={category.icon}
                  styleName={category.styleName}
                  isIconAvailable={category.isIconAvailable}
                  iconAsset={category.iconAsset}
                />
              );
            })}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;

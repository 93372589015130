import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, Spinner, Button } from "reactstrap";
import DependentsDeleteModal from "./DependentsDeleteModal";
import DependentsNotifyModal from "./DependentsNotifyModal";
import DependentsModal from "./DependentsModal";
import moment from "moment";
import MomentConfig from "../../../common/MomentConfig";
import defaultAvatar from "../../../assets/avatar.png";
import { ListItemAvatar } from "@material-ui/core";


const DependentsTable = (props) => {
  const [PhysicianCareTypes, setPhysicianCareTypes] = useState([]);
  const avatar = defaultAvatar;

  const ActionFormatter = (cell, row) => {
    return (
      <>
        <Button className="outline-primary ml-2 mb-0 delete-table-row" title="View dependent details">
          <FontAwesomeIcon
            icon={faEye}
            fixedWidth
            className="align-middle mr-2 "
            title="View dependent details"
          />
        </Button>
        <DependentsModal
          job="edit"
          dependent_id={row.id}
          TableRenderPhysicians={props.TableRenderPhysicians}
          
        />
        {
          row.careTypeId!=="1"?<DependentsNotifyModal
          id={row.id}
          notify={row.notify}
          TableRenderPhysicians={props.TableRenderPhysicians}
        />:null
        }
        {
          row.careTypeId!=="1"?<DependentsDeleteModal
          id={row.id}
          TableRenderPhysicians={props.TableRenderPhysicians}
        />:null
        }
        
         {/* <Button className="outline-primary ml-2 mb-0 physician_edit_button delete-table-row">
          <FontAwesomeIcon
            icon={row.notify==1 ? faBell : faBellSlash}
            fixedWidth
            className="align-middle mr-2 "
          />
        </Button> */}
      </>
    );
  };

  const externalFormatter = (cell) => {
    if (cell === "1") {
      return "Yes";
    } else {
      return "No";
    }
  };

  const dobFormatter = (cell) => {
    return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A";
  };

  const expandRow = {
    renderer: (row) => (
      <div className="CareTeamTableExpands">
        <span>{`Address :  ${row.address ? row.address : ''}`}</span>
        <span>{`Country :  ${row.countryName}`}</span>
        <span>{`State :  ${row.state ? row.state : '' }`}</span>
        <span>{`City :  ${row.city ? row.city : '' }`}</span>
        <span>{`Post Code :  ${row.zipcode ? row.zipcode : ''}`}</span>
        <span>{`Mobile :  ${row.mobilePhone ? row.mobilePhone : ''}`}</span>
      </div>
    ),
  };

  const ProfileFormatter = (cell, row) => {
    return (
      <>
      <img
                    alt="Profile pic"
                    src={row.profileImageURL ? row.profileImageURL : avatar}
                    className="rounded-circle img-responsive mt-2"
                    width="32"
                    height="32"
                  /> 
      <span > {row.name}</span>
      </>
    )
  }

  const tableColumns = [
    // {
    //   dataField: "profileImageURL",
    //   text: "",
    //   formatter: ProfileFormatter,
    //   width: '50px'
    // },
    {
      dataField: "name",
      text: "Name",
      formatter: ProfileFormatter,
      width: '50px'
    },
    {
      dataField: "relation",
      text: "Relation",
    },
    {
      dataField: "dob",
      text: "DOB",
      formatter: dobFormatter,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "status",
      text: "Actions",
      formatter: ActionFormatter,
    },
  ];

  let loader = null;
  if (props.PhysiciansLoading) {
    loader = <Spinner />;
  }

  useEffect(() => {
if(PhysicianCareTypes && PhysicianCareTypes.length )
    props.TableRenderPhysicians();
  }, [PhysicianCareTypes]); // eslint-disable-line

  useEffect(() => {
    if (props.PhysicianCareTypes) {
      setPhysicianCareTypes(props.PhysicianCareTypes);
    }
  }, [props.PhysicianCareTypes]);

  return (
    <div className="PhysiciansTable">
      {loader}
      <Card>
        <CardBody className="health-card">
          {props.PhysiciansNoDataFound ? (
            <p>No Data Found</p>
          ) : (
            <BootstrapTable
              keyField="id"
              data={props.data}
              columns={tableColumns}
              bordered={false}
              expandRow={expandRow}
              bootstrap4
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DependentsTable;

import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Button, Modal, ModalBody, Col, Row } from "reactstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons";

const colors = [
  {
    name: "Primary",
    value: "primary",
  },
];

const DependentsNotifyModal = (props) => {

console.log(props);
 


  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const notifyDependent = (id, notify) => {
// console.log('notify', notify);
      Axios.get(
        API_ENDPOINT+"/patient/dependent/notify/"+id+"/"+notify).then((res) => {
        props.TableRenderPhysicians();
        toastr.success("Dependent notification updated", "Dependent notification updated successfully");
      })
      .catch(function (error) {
        toastr.error("Update Unsuccessful");
      });
    
    }

   

  const initOpenModals = () => {
    let modals = {};

    colors.forEach((color, index) => {
      modals = Object.assign({}, modals, { [index]: false });
    });

    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  return (
    <>
      {colors.map((color, index) => (
        <React.Fragment key={index}>
          <Button className="outline-primary ml-2 mb-0  delete-table-row" title="Enable/Disable notification" outline onClick={(e) => {toggle(index); e.stopPropagation();}}>
            <FontAwesomeIcon
              icon={props.notify==1 ? faBell : faBellSlash}
              fixedWidth
              className="align-middle mr-2"
              title="Enable/Disable notification"
            />
          </Button>
          <Modal
            isOpen={openModals[index]}
            // toggle={() => toggle(index)}
            centered
          >
            <ModalBody className="text-center m-3">
              <Row>
                <Col md="12">
                  <p className="text-center">{props.notify==1 ? 'Disable email notification for' : 'Enable email notification for'} this dependent?</p>
                  <Button
                    color="primary"
                    onClick={() => {notifyDependent(props.id, props.notify);toggle(index);}}
                  >
                    Yes
                  </Button>{" "}
                  <Button color="outline-primary mr-2" onClick={() => toggle(index)}>
                    No
                  </Button>
                  
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </React.Fragment>
      ))}
    </>
  );
};

export default DependentsNotifyModal;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AiOutlineFile, AiOutlineFolder } from "react-icons/ai";
import AddDocumentModal from "./AddDocumentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faFilePdf,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import FileViewModal from "./FileViewModal";
import { cookieService } from '../../../../services/cookieService';

const FILE_ICONS = {
  gif: <FontAwesomeIcon icon={faImage} />,
  GIF: <FontAwesomeIcon icon={faImage} />,
  png: <FontAwesomeIcon icon={faImage} />,
  PNG: <FontAwesomeIcon icon={faImage} />,
  jpg: <FontAwesomeIcon icon={faImage} />,
  JPG: <FontAwesomeIcon icon={faImage} />,
  jpeg: <FontAwesomeIcon icon={faImage} />,
  JPEG: <FontAwesomeIcon icon={faImage} />,
  pdf: <FontAwesomeIcon icon={faFilePdf} />,
  PDF: <FontAwesomeIcon icon={faFilePdf} />,
  docx: <FontAwesomeIcon icon={faFileAlt} />,
  DOCX: <FontAwesomeIcon icon={faFileAlt} />,
  doc: <FontAwesomeIcon icon={faFileAlt} />,
  DOC: <FontAwesomeIcon icon={faFileAlt} />,
  tiff: <FontAwesomeIcon icon={faImage} />,
  TIFF: <FontAwesomeIcon icon={faImage} />,
};

const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: #46b7ee;
  }
`;
const StyledFolder = styled.div`
  padding-left: 20px;

  .folder--label {
    cursor: pointer;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "auto" : "0")};
  overflow: hidden;
`;

function Identification() {
  const [Documents, setDocuments] = useState([]);
  const [Folders, setFolders] = useState([]); // eslint-disable-line
  const [NoDataFound, setNoDataFound] = useState(false);
  const [TreeData, setTreeData] = useState([]);

  const getChildrens = (files) => {
    return files.map((a) => {
      return {
        type: "file",
        name: a.fileName,
        id: a.id,
        path: a.filePath,
        title: a.title,
      };
    });
  };

  const PopulateData = () => {
    setFolders(
      Documents.map((key) => { // eslint-disable-line
        TreeData.push({
          type: "folder",
          name: key.category,
          id: key.id,
          childrens: getChildrens(key.documents),
        });
      })
    );
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // let dependentID = cookieService.get('dependentID');

  const FetchDocuments = () => {
    setTreeData([]);   
    // let url = "";
    // if(dependentID){url = "/patient/document/list/identification/" + dependentID}
    // else{url = "/patient/document/list/identification"}
    // Axios.get(API_ENDPOINT + url)
    Axios.get(API_ENDPOINT + "/patient/document/list/identification")
      .then((res) => {
        if (res.status === 200) {
          setDocuments(res.data.data);
        } else {
          setNoDataFound(true);
        }
      })
      .catch((error) => {
        setNoDataFound(true);
      });
  };

  const File = ({ name, path, title, id, data }) => {
    let ext = name.split(".")[1];

    let filetype = "";

    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "JPEG" ||
      ext === "png" ||
      ext === "PNG" 
    ) {
      filetype = "image";
    } else if (ext === "pdf" || ext === "PDF") {
      filetype = "pdf";
    } else if (
      ext === "doc" ||
      ext === "DOC" ||
      ext === "docx" ||
      ext === "DOCX"
    ) {
      filetype = "doc";
    } else if (ext === "tiff" || ext === "TIFF") {
      filetype = "tiff";
    }else{
      filetype = "doc";
    }

    return (
      <StyledFile>
        {FILE_ICONS[ext] || <AiOutlineFile />}
        <FileViewModal
          path={path}
          title={title}
          name={name}
          id={id}
          filetype={filetype}
          RenderDocuments={FetchDocuments}
        />
      </StyledFile>
    );
  };

  const Folder = ({ name, children, id }) => {
    const [isOpen, setIsOpen] = useState(true);
    const handleToggle = (id) => {
      setIsOpen(!isOpen);
    };

    return (
      <StyledFolder>
        <div className="folder--label" onClick={() => handleToggle(id)}>
          <AiOutlineFolder />
          <span>{name}</span>
        </div>
        <Collapsible isOpen={isOpen}>{children}</Collapsible>
      </StyledFolder>
    );
  };

  const TreeRecursive = ({ data }) => {
    // loop through the data
    return data.map((item) => { // eslint-disable-line
      // if its a file render <File />
      if (item.type === "file") {
        return (
          <File
            name={item.name}
            path={item.path}
            title={item.title}
            id={item.id}
          />
        );
      }
      // if its a folder render <Folder />
      if (item.type === "folder") {
        return (
          <Folder name={item.name} id={item.id}>
            {/* Call the <TreeRecursive /> component with the current item.childrens */}
            <TreeRecursive data={item.childrens} />
          </Folder>
        );
      }
    });
  };
  const Tree = ({ data, children }) => {
    const isImparative = data && !children;

    return (
      <StyledTree>
        {isImparative ? <TreeRecursive data={data} /> : children}
      </StyledTree>
    );
  };

  Tree.File = File;
  Tree.Folder = Folder;

  useEffect(() => {
    FetchDocuments();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (Documents) PopulateData();
  }, [Documents]); // eslint-disable-line

  return (
    <div className="tree-column">
      {/* <h4>Identification</h4> */}
      <AddDocumentModal
        RenderDocuments={FetchDocuments}
        setNoDataFound={setNoDataFound}
      />
      {NoDataFound ? (
        <p className="nodatafound">No documents found</p>
      ) : (
        <Tree data={TreeData} />
      )}
    </div>
  );
}

export default Identification;

import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { GlobalState } from './../context/ContextApi'
import SignIn from '../components/SignIn/SignIn';
import SignUp from '../components/SignUp/SignUp';
import Messages from '../components/Messages/Messages';
import Providers from '../components/Providers/Providers';
import Profile from '../components/Settings/Profile/Profile';
import InstantCall from '../components/Call/InstantCall';
import Account from '../components/Settings/Account/Account';
import Language from '../components/Settings/Language/Language';
import Security from '../components/Settings/Security/Security';
import Notification from '../components/Settings/Notification/Notification';
import Verification from '../components/Verification/Verification';
import Email from '../components/Verification/Email';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import ResetPassword from '../components/ResetPassword/ResetPassword';
import Booking from '../components/Booking/Booking';
import SlotCheckout from '../components/SlotCheckout/SlotCheckout';
import Index from '../components/Index/Index';
import MyAppointments from "../components/Consultation/MyAppointments/MyAppointments";
import BookingConfirmation from "../components/BookingConfirmation/BookingConfirmation";
import HealthProfilePage from "./../components/HealthProfile/HealthProfile"
import VideoConsultation from '../components/Consultation/VideoConsultation/VideoConsultation'
import ClinicalDocuments from "./../components/ClinicalDocuments/ClinicalDocuments"
import IdentificationDocuments from "./../components/Settings/IdentificationDocuments/IdentificationDocuments"
import CareTeam from "../components/CareTeam/CareTeam";
import Dependents from "../components/Dependents/Dependents";
import IosappInterstitial from "../components/IosappInterstitial/IosappInterstitial"
import ConsulatationInterstitial from "../components/ConsulatationInterstitial/ConsulatationInterstitial"
import AppointmentCompleted from "../components/Consultation/OpenTok/Publisher/AppointmentCompleted";
import DependentAppointmentCompleted from "../components/Consultation/OpenTok/Publisher/DependentAppointmentCompleted";
import AppointmentCancelled from '../components/Consultation/MyAppointments/AppointmentCancelled';
import OTP from '../components/MobileVerification/OTP';
import MobileNumberVerification from '../components/MobileVerification/MobileNumberVerification';
import Insurance from "../components/Settings/InsuranceDetails/Insurance";
import MainAccount from "../components/Settings/Account/MainAccount";
import { cookieService } from '../services/cookieService';


const Routes = () => {
 
  const { Authenticated, isProfileUpdated,isMobileNoVerified } = useContext(GlobalState);
  let dependentID = cookieService.get('dependentID') ? cookieService.get('dependentID') : 0;

  if (isProfileUpdated === false && Authenticated && isMobileNoVerified) {
    return (
      <Router>
        <Switch>
          <Route path="/">
            <Profile />
          </Route>
        </Switch>
      </Router>
    )
  }

  return (
    <>
      <Router>
        <Switch>

          <Route exact path="/signup"><SignUp /> </Route>
          <Route exact path="/forgot-password"><ForgotPassword /></Route>
          <Route path="/forgot-password/:verifyToken" component={ResetPassword} />
          <Route path="/verification/:verifyToken" component={Verification} />
          <Route path="/verify-user-email/:verifyToken" component={Email} />
          <Route path="/iosapp/:appointmentid/:token" component={IosappInterstitial} />

          {/* <Route exact path="/">
            {Authenticated ? <Redirect to="/home" /> : <SignIn />}
          </Route>
          <Route exact path="/home">
            {Authenticated ? <Index /> : <Redirect to="/" />}
          </Route> */}
          <Route exact path="/">
            {Authenticated ? <Redirect to="/home" /> : <SignIn />}
          </Route>
          <Route exact path="/home">
            {Authenticated ? <Index /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/settings/profile">
            {Authenticated ? <Profile /> : <SignIn />}
          </Route>
          <Route exact path="/settings/account">
            {Authenticated ? <Account /> : <SignIn />}
          </Route>
          <Route exact path="/settings/language">
            {Authenticated ? <Language /> : <SignIn />}
          </Route>
          <Route exact path="/settings/security">
            {Authenticated ? <Security /> : <SignIn />}
          </Route>
          <Route exact path="/settings/notification">
            {Authenticated ? <Notification /> : <SignIn />}
          </Route>
          <Route exact path="/call/create">
            {Authenticated ? <InstantCall /> : <SignIn />}
          </Route>
          <Route exact path="/messages">
            {Authenticated ? <Messages /> : <SignIn />}
          </Route>
          <Route exact path="/my-appointments">
            {Authenticated ? <MyAppointments /> : <SignIn />}
          </Route>
          <Route exact path="/booking-confirmation">
            {Authenticated ? <BookingConfirmation /> : <SignIn />}
          </Route>
          <Route exact path="/providers">
            {Authenticated ? <Providers /> : <SignIn />}
          </Route>
          <Route exact path="/book-appointment">
            {Authenticated ? <Booking /> : <SignIn />}
          </Route>
          <Route exact path="/slotCheckout">
            {Authenticated ? <SlotCheckout /> : <SignIn />}
          </Route>
          <Route path="/video-consultation">
            {Authenticated ? <VideoConsultation /> : <SignIn />}
          </Route>
          <Route exact path="/health-profile">
            {Authenticated ? <HealthProfilePage /> : <SignIn />}
          </Route>
          <Route exact path="/my-clinical-records">
            {Authenticated ? <ClinicalDocuments /> : <SignIn />}
          </Route>
          <Route exact path="/settings/identification">
            {Authenticated ? <IdentificationDocuments /> : <SignIn />}
          </Route>
          <Route exact path="/settings/insurance">
            {Authenticated ? <Insurance /> : <SignIn />}
          </Route>
          <Route exact path="/settings/main-account">
            {(Authenticated && dependentID != 0) ? <MainAccount /> : <SignIn />}
          </Route>
          <Route exact path="/care-team">
            {Authenticated ? <CareTeam /> : <SignIn />}
          </Route>
          <Route exact path="/my-dependents">
            {(Authenticated && dependentID==0) ? <Dependents /> : <SignIn />}
          </Route>
          <Route exact path="/consultation-interstitial">
            {Authenticated ? <ConsulatationInterstitial /> : <SignIn />}
          </Route>
          <Route exact path="/appointment-completed">
            {Authenticated ? <AppointmentCompleted/> : <SignIn />}
          </Route>
          <Route exact path="/dependent-appointment-completed">
            {Authenticated ? <DependentAppointmentCompleted/> : <SignIn />}
          </Route>
          <Route exact path="/appointment-cancelled">
            {Authenticated ? <AppointmentCancelled/> : <SignIn />}
          </Route>

          <Route exact path="/otp-verification">
          {Authenticated ? <OTP/> : <SignIn />} 
          </Route>
          <Route exact path="/mobile-verification">
          {Authenticated ?  <MobileNumberVerification/> : <SignIn />} 
          </Route>
          
        </Switch>
      </Router>
    </>
  )
};

export default Routes;
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import { useInput } from "../../../hooks/input-hook";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { cookieService } from "../../../services/cookieService";
import ReactTooltip from 'react-tooltip';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from 'react-toggle-button'
import { GlobalState } from '../../../context/ContextApi';
import MobileNumberVerificationModal from '../../MobileVerification/MobileNumberVerificationModal'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import Header from "../../Layout/Header/Header";
import HeaderTitle from "../../Layout/Header/HeaderTitle";
import PageLayout from "../../Layout/PageLayout/PageLayout";
import SubNav from "../SubNav";
import styles from "./Account.module.css";

let dependentID = cookieService.get('dependentID');

const EmailInfo = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const [ShowEditEmail, setShowEditEmail] = useState(false);
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const { value: newEmail, bind: bindnewEmail } = useInput("");
  const { value: password, bind: bindpassword } = useInput("");
  const [responseFields, setResponseFields] = useState({ message: "Error occured", status: false }); // eslint-disable-line
  const [errorStatus, setErrorStatus] = useState(false);

  const {
    value: currentEmail,
    bind: bindcurrentEmail, // eslint-disable-line
    setValue: setcurrentEmail,
  } = useInput("");

  

  useEffect(() => {
    let patient = JSON.parse(localStorage.getItem("userDetail"));
   
    // patient = Details;
    console.log(patient);
    if (patient !== null) {
      if(dependentID!=0){
        const filteredUsers = patient[0].dependents.filter(user => user.id === dependentID);
        console.log("Acc", filteredUsers);
        setcurrentEmail(filteredUsers[0].email);

      }else{
        setcurrentEmail(patient[0].email);
      }
      
    }
  }, []); // eslint-disable-line

  const ToggleEmailEdit = () => {
    setShowEditEmail(!ShowEditEmail);
  };

  const handleSubmit = (event, errors, values) => {
    setErrorStatus(false);
    setSpinnerEnable(!spinnerEnable);
    let request = {};
    if(dependentID){
      request = {
        currentEmail: currentEmail,
        newEmail: newEmail,
        password: password,
        dependentID: dependentID
      };
    }else{
      request = {
        currentEmail: currentEmail,
        newEmail: newEmail,
        password: password,
      };
    }
    
    axios
      .post("/user/change-email", request)
      .then((profile) => {
        if (profile.status === 201) {
          if(!dependentID){
            setErrorStatus(!errorStatus);
            setSpinnerEnable(false);
            localStorage.setItem("IsEmailVerification", true);
            cookieService.remove("token", {
              path: "/",
              expires: "Wed, 08 Apr 1970 12:43:46 GMT",
              Domain: window.location.hostname,
            });
            cookieService.remove("refresh_token", {
              path: "/",
              expires: "Wed, 08 Apr 1970 12:43:46 GMT",
              Domain: window.location.hostname,
            });
            localStorage.removeItem("userDetail");
            history.push("/");
          }else{
            toastr.success(profile.data.title);
            history.push("/settings/profile");
          }
         
        } else {
          responseFields.message = "Error Occured";
          toastr.error(
            responseFields.message
          );
          responseFields.status = false;
        }
      })
      .catch(function (error) {
        const errorMsg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.title) ||
          (error && error.response && error.response.statusText);
        setErrorStatus(true);
        responseFields.message = errorMsg;
        toastr.error(
          responseFields.message
        );
        responseFields.status = false;
        setSpinnerEnable(false);
      });
  };



  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Email Address
          </CardTitle>
        </CardHeader>
        <CardBody className={styles.cardBody}>
          <AvForm onValidSubmit={handleSubmit}>
            <Row>
              <Col md="12" className="mb-2">
                <div className={styles.currentEmail}>
                  <strong>{currentEmail}</strong>
                </div>
                <FontAwesomeIcon
                  icon={faEdit}
                  fixedWidth
                  className="align-middle mr-2 email-edit-icon "
                  onClick={ToggleEmailEdit}
                />
                <div className={ShowEditEmail ? "collapse show" : "collapse"}>
                  <div class="health-profile-alert alert-secondary mt-3">
                    Please be informed that changing email address will
                    require re-authentication !
                    </div>
                  <FormGroup>
                    <Label for="email">{t("newEmail")}</Label>
                    <AvField
                      name="email"
                      type="email"
                      placeholder={t("email")}
                      validate={{ email: true, maxLength: { value: 254 } }}
                      id="email"
                      {...bindnewEmail}
                      errorMessage={t("emailErrorMessage")}
                      required
                    />
                  </FormGroup>
                  <Label>{t("password")}</Label>
                  <AvField
                    bsSize="lg"
                    name="password"
                    type="password"
                    placeholder={t("enterPasswordPH")}
                    id="password"
                    {...bindpassword}
                    errorMessage={t("requiredField")}
                    required
                  />
                  {spinnerEnable && (
                    <div className="spinner-wrapper">
                      <Spinner
                        color="dark"
                        type="grow"
                        className="mr-2"
                        size="sm"
                      />
                      <Spinner
                        color="dark"
                        type="grow"
                        className="mr-2"
                        size="sm"
                      />
                      <Spinner
                        color="dark"
                        type="grow"
                        className="mr-2"
                        size="sm"
                      />
                    </div>
                  )}
                  <Button color="primary" className={styles.center_save}>Save</Button>{" "}
                  <Button
                    color="primary"
                    outline
                    onClick={() => setShowEditEmail(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

const PasswordInfo = () => {

  const [ShowEditPassword, setShowEditPassword] = useState(false)
  const TogglePasswordEdit = () => {
    setShowEditPassword(!ShowEditPassword);
  }

  const { t } = useTranslation();
  const [spinnerEnable, setSpinnerEnable] = useState(false);
  const [responseMessage, setResponseMessage] = useState([]);
  const { value: currentPassword, bind: bindcurrentPassword } = useInput("");
  const { value: newPassword, bind: bindnewPassword } = useInput("");
  const { value: confirmassword, bind: bindconfirmassword } = useInput(""); // eslint-disable-line

  const [responseFields, setResponseFields] = useState({ // eslint-disable-line
    message: "Error occured",
    status: false,
  });
  const [errorStatus, setErrorStatus] = useState(false); // eslint-disable-line

  const signOut = () => {
    cookieService.remove('token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    cookieService.remove('refresh_token', { path: "/", expires: "Wed, 08 Apr 1970 12:43:46 GMT", Domain: window.location.hostname });
    localStorage.removeItem("userDetail");
    window.location = "/"
  }

  const handleSubmit = (event, errors, values) => {
    setErrorStatus(false);
    setSpinnerEnable(!spinnerEnable);
    const request = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    axios
      .post("/user/change-password", request)
      .then((profile) => {
        if (profile.status === 201) {
          setErrorStatus(true);
          setResponseMessage([...responseMessage, profile.title]);
          responseFields.message = t("passwordUpdateSucessMessage");
          toastr.success(responseFields.message);
          responseFields.status = true;
          setSpinnerEnable(false);
          signOut();
        } else {
          responseFields.message = "Error Occured";
          toastr.error(
            responseFields.message
          );
          responseFields.status = false;
        }
      })
      .catch(function (error) {
        const errorMsg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.title) ||
          (error && error.response && error.response.statusText);
        setErrorStatus(true);
        responseFields.message = errorMsg;
        toastr.error(
          responseFields.message
        );
        responseFields.status = false;
        setSpinnerEnable(false);
      });
  };


  const passwordFormat = () => {
    return (
      <div>
        <span className={styles.password_tooltip} data-for="tool-tip" data-tip="Should be 8 or more characters with a mix of upper-case letters, lower-case letters, numbers and special characters."
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="align-middle"
          />
        </span>
        <ReactTooltip id="tool-tip" />
      </div>
    )
  }

  return (
    <>
      <Card>
        <CardHeader className="card-header mt-2 mb-2 remove-margins">
          <CardTitle tag="h5" className="mb-0 inlined mr-2">
            Change Password
          </CardTitle>
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle ml-2 email-edit-icon "
            onClick={TogglePasswordEdit}
          />
        </CardHeader>
        <div className={ShowEditPassword ? "collapse show" : "collapse"}>
          <CardBody className={styles.cardBody}>
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col md="12" className="reduce-padd">
                  <Label>{t("currentPassword")}</Label>
                  <AvField
                    bsSize="lg"
                    name="currentPassword"
                    type="password"
                    placeholder={t("currentPassword")}
                    id="currentPassword"
                    {...bindcurrentPassword}
                    errorMessage={t("requiredField")}
                    required
                  />
                  <Label>
                    <div >
                      {t("newPassword")} {passwordFormat()}
                    </div>
                  </Label>
                  <AvField
                    bsSize="lg"
                    name="nwPassword"
                    type="password"
                    placeholder={t("newPassword")}
                    id="nwPassword"
                    validate={{
                      maxLength: { value: 24 },
                      pattern: { value: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&-+=()])(?=\\S+$).{8,24}$', errorMessage: 'Should be 8 or more characters with a mix of upper-case letters, lower-case letters, numbers & special characters' }
                    }}
                    minLength="6"
                    {...bindnewPassword}
                    required
                  />
                  <Label>{t("confirmPassword")}</Label>
                  <AvField
                    bsSize="lg"
                    name="cPassword"
                    type="password"
                    validate={{
                      maxLength: { value: 24 }
                    }}
                    placeholder={t("confirmPassword")}
                    id="cPassword"
                    {...bindconfirmassword}
                    errorMessage={t("confirmPasswordErrorMessage")}
                    validate={{ match: { value: "nwPassword" } }} // eslint-disable-line
                    required
                  />
                </Col>
              </Row>
              {spinnerEnable && (
                <div className="spinner-wrapper">
                  <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                  <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                  <Spinner color="dark" type="grow" className="mr-2" size="sm" />
                </div>
              )}
              <Button color="primary" className={styles.center_save}>Save</Button>{" "}
              <Button
                color="primary"
                outline
                onClick={() => setShowEditPassword(false)}
              >
                Cancel
              </Button>
            </AvForm>
          </CardBody>
        </div>
      </Card>
    </>
  );
};

const TwoFactorAuthentication = () => {
  // const [ShowEditEmail, setShowEditEmail] = useState(false);
  const [responseFields, setResponseFields] = useState({ message: "Error occured", status: false }); // eslint-disable-line
  const [toggleButtonState, setToggleButtonState] = useState(false);
  const [toggleStatus,setToggleStatus] = useState("idle");
  const { Details, setDetails } = useContext(GlobalState);//eslint-disable-line
  const [buttonToggled, setButtonToggled] = useState(0)
  const [mobileVerificationModalStatus, setMobileVerificationModalStatus] = useState(false);

  let mobileNumber = Details ? `${Details.phone}`:"";
  let phoneNumberAfterMasking = mobileNumber.replace(/\d(?=\d\d\d\d)/g, "*");
  let mobileNumberAfterMasking = Details ? `${Details.countryCode}${phoneNumberAfterMasking}` : ""


  const toggleApi = () => {
    const request = {
      twoFactorButtonStatus: toggleButtonState,
    };
    axios
      .post("/toggle-2FA-status", request)

      .then((response) => {
        if (response.data.data.boolean_status) {
          Details.is2FAEnabled = '1';
          setToggleStatus("ON");
          toastr.success("Success", "Two factor authentication ON");

          // is2FAEnabledToggleStatus = "1";
          // Details.is2FAEnabled = is2FAEnabledToggleStatus;
        }
        else {
          Details.is2FAEnabled = '0';
          setToggleStatus("OFF");
          toastr.success("Success", "Two factor authentication OFF");
          // is2FAEnabledToggleStatus = "0"
          // Details.is2FAEnabled = is2FAEnabledToggleStatus;
        }

      })
      .catch(function (error) {

      });
  }

 

  const handleChange = (event, errors, values) => {
    if (toggleButtonState) {
      setToggleButtonState(false);
      setToggleStatus("OFF");
    }
    else {
      setToggleButtonState(true);
      setToggleStatus("ON");
    }
    setButtonToggled(buttonToggled + 1)
  };

  useEffect(() => { if (buttonToggled) toggleApi() }, [buttonToggled]) //eslint-disable-line

  useEffect(() => {
    if (Details && Details.is2FAEnabled === '1') {
      setToggleButtonState(true);
      setToggleStatus("ON");
    }
    else {
      setToggleButtonState(false);
      setToggleStatus("OFF");
    }
  }, [Details])

  // useEffect(() => {
  //   if (Details && Details.isMobileNoVerified === '0') {
  //     setToggleButtonState(true);
  //   }
  // }, [Details])


  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            Two Factor Authentication
          </CardTitle>
          {/* <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle ml-2 email-edit-icon "
            onClick={ToggleEmailEdit  }
          /> */}
        </CardHeader>
        <CardBody className={styles.cardBody}>
          <Row>
            <Col md="3">
              <p>Two Factor authentication is {toggleButtonState ? 'ON' : 'OFF'}</p>
            </Col>
            <Col md="4">
              <ToggleButton
                inactiveLabel={''}
                activeLabel={''}
                value={toggleButtonState}
                onToggle={() => {
                  handleChange();
                }}
              />
            </Col>
          </Row>
          {(Details.isMobileNoVerified === '0' && toggleStatus=== 'ON') ?
            <Row>
              <Col>
                <small>Your mobile number {mobileNumberAfterMasking} is not verified for two factor authentication</small>{" "}<small><Link  onClick={() => { setMobileVerificationModalStatus(true) }}>Click here to verify</Link></small>
              </Col>
            </Row> : ""}
          <Row>
            <Col md="11">
              <div class="two-step-toggle-description mt-3">
                Two-factor authentication is an extra layer of security designed to make sure you're the only person who can access your account.
                If two-factor authentication is ON, a 6 digit OTP number will be sent to the mobile verified with this account when you login.
             </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <MobileNumberVerificationModal
        mobileVerificationStatus={mobileVerificationModalStatus}
        setMobileVerificationModalStatus={setMobileVerificationModalStatus} />
    </>
  );
};

const Account = () => {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Header>
        <HeaderTitle>{t("myAccount")}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/settings/account">{t("myAccount")}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t("account")}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col md="3" xl="2">
          <SubNav />
        </Col>
        <Col md="9" xl="10">
          <EmailInfo />
          {!dependentID ? <>
          <PasswordInfo />
          <TwoFactorAuthentication /> </>: null }
        </Col>
      </Row>
    </PageLayout>
  );
};

export default Account;
